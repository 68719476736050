import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ALL_FILTERS_TEXT, FILTER_TYPES, FILTER_VALIDATION, FILTER_ICON } from '../../constant/tvd-constant';
import { FilterService } from '../../services/filter.service';
import { IFilterTracker } from '../../interfaces/IFilterTracker';
import { TranslationService } from '../../services/translation.service';
import { CustomerApi, ICustomerFilters, ICustomerInfo } from '../../interfaces/customer.interface';
import { FormControl } from '@angular/forms';
import { UOM, USD } from '../../../../shared/constants/app.constants';
import { UserPreferenceService } from '../../services/user-preference.service';

@Component({
  selector: 'app-optional-filters',
  templateUrl: './optional-filters.component.html',
  styleUrl: './optional-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionalFiltersComponent {

  @Output() filterRequestPayload: EventEmitter<any> = new EventEmitter();

  filterType: any = FILTER_TYPES;
  savedFilterType: string = this.filterType.CVOC;
  selectedFilterType: string = this.filterType.CVOC;
  disableApply: boolean = true;
  LABEL_TEXT_ALLFILTERS: any = ALL_FILTERS_TEXT;
  displayAllFilter: boolean = false;
  selectedFilterCount: number = 0;
  isBusy: boolean = true;
  customerInfo: ICustomerInfo;
  siteCtrl = new FormControl();
  siteFilter: any;
  FILTER_ICON = FILTER_ICON;
  unitOfMeasure: string = UOM;
  currencyCode: string = USD;
  public filterTracker: IFilterTracker[] = [...FILTER_VALIDATION];
  programFilters: CustomerApi;

  constructor(
    private cdref: ChangeDetectorRef,
    private _filterService: FilterService,
    public translationService: TranslationService,
    private userPreferenceService: UserPreferenceService
  ) { }
  ngOnInit() {
    this.getUserPreferences();
    this._filterService.customerInfo.subscribe((x) => {
      if (x) {
        this.customerInfo = x;
        this.isBusy = true;
        this._filterService.getSites(this.customerInfo?.customerID).subscribe(
          res => {
            this.siteFilter = res
            this.isBusy = false;
          },
          err => { },
          () => {
            this.isBusy = false;
          }
        );
      }
    });

  }

  checkToEnableApply(enable: any) {
    let count = 0;
    this.disableApply = false;
    let currentToggle: string = null;
    this._filterService.currentToggle.subscribe(x => currentToggle = x);
    this.filterTracker.forEach(x => {
      if (x.filterName.toLowerCase() === enable.type.toLowerCase()) {
        x.error = enable.apply;
        x.data = enable.data;
      }
      if (x.filterName === 'division' || x.filterName === currentToggle || x.filterName === 'opType') {
        if (!x.error) {
          count += x.data ? 1 : 0;
          count = Math.max(0, Math.min(count, 3));
        } else {
          this.disableApply = true;
        }
      }
    });

    this.selectedFilterCount = count;
  }

  changeToggle(toggleName: string) {
    this.selectedFilterType = toggleName;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  openAllFilterView() {
    this.selectedFilterType = this.savedFilterType;
    this.displayAllFilter = !this.displayAllFilter;
    this._filterService.filterContainerName.next('allFilters')
    this._filterService.filterContainerName.subscribe(
      name => {
        if (name.toLowerCase() === 'customerfilter' && this.displayAllFilter) {
          this.displayAllFilter = false;
        }
      }
    )
    this.filterTracker.forEach(x => { x.data = false });
  }

  calculateTotalFilters() {
    const currrentToggle = this._filterService.currentToggle.value;
    this.savedFilterType = currrentToggle;
    const startDate = this._filterService.startDate.value
    const endDate = this._filterService.endDate.value
    const cvoc = this._filterService.selectedCVOC.value;
    const evoc = this._filterService.selectedEVOC.value;
    const division = this._filterService.selectedCustomerDivision.value;
    const site = this._filterService.addedSites$.value;
    const opType = this._filterService.selectedOperatingType$.value;

    const requestBody = {};

    if (cvoc && currrentToggle == 'CVOC') {
      cvoc.forEach(x => {
        const a = 'hierarchyLevel' + x.level + 'Id';
        requestBody[a] = x.hierarchyID;
      });
    }
    if (evoc && currrentToggle == 'EVOC') {
      evoc.forEach(y => {
        requestBody[y.hierarchyName] = y.selectedValue;
      });

    }

    if (division) {
      const div = division.divisions.map(x => x);
      if (div.length > 0) {
        requestBody['division'] = div;
      }
    }

    if (site && currrentToggle == this.filterType.SITES) {
      const arr = site.map(x => x.siteId);
      if (arr.length > 0) {
        requestBody['siteId'] = arr;
      }
    }
    if (opType) {
      requestBody['operatingType'] = opType.operatingType;
      requestBody['operatingSubType'] = opType.subOpType;
    }
    requestBody['startDate'] = startDate;
    requestBody['endDate'] = endDate;
    requestBody['unitOfMeasure'] = this.unitOfMeasure;
    requestBody['currencyCode'] = this.currencyCode;
    this._filterService.appliedSites$.next(site);
    this._filterService.appliedCVOC.next(cvoc);
    this._filterService.appliedEVOC.next(evoc);
    this._filterService.appliedOperatingType$.next(opType);
    this._filterService.appliedCustomerDivision.next(division);
    this.openAllFilterView();
    this.filterRequestPayload.emit(requestBody);
  }

  getUserPreferences(): any {
    this.userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences) {
        this.currencyCode = preferences.currency;
        this.unitOfMeasure = preferences.uom;
      }
    })
  }
}