import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { ISite, ISiteData } from '../../interfaces/ISite.interface';
import { ERROR_ICON, SITEFILTER_SEARCH_ICON, SITE_IDENTIFIER, SITE_NOT_FOUND_MSG, SITE_SEARCH } from '../../../../shared/constants/app.constants';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';
import { ScrollService } from './../../services/scroll.service';

@Component({
  selector: 'app-site-filter',
  templateUrl: './site-filter.component.html',
  styleUrl: './site-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteFilterComponent {

  @Output() enableApply: EventEmitter<any> = new EventEmitter();
  @Input() siteFilter
  siteKeyword = SITE_IDENTIFIER;
  matcher = new CustomerNameErrorStateMatcher();
  customerInfo: ICustomerInfo;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  siteCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  addedSites: ISite[] = [];
  siteList: ISiteData;
  siteFilteredList: Observable<ISite[]>
  searchIcon = SITEFILTER_SEARCH_ICON;
  SiteErrorText = SITE_NOT_FOUND_MSG;
  searchLabel = SITE_SEARCH;
  @ViewChild('siteInput') siteInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete!: MatAutocompleteTrigger;
  errorIcon: string = ERROR_ICON;

  constructor(
    private _filterService: FilterService,
    private scrollService: ScrollService) { }


  ngOnInit() {
    window.addEventListener('scroll', this.onScroll.bind(this), true);
    this._filterService.currentToggle.next(this.siteKeyword);
    this._filterService.selectedEVOC.next(null);//saved EVOC made null
    this._filterService.selectedCVOC.next(null);//saved EVOC made null
    this.fetchSitesForCustomer();
    const savedSites = this._filterService.appliedSites$.value;
    if (savedSites && savedSites?.length > 0) {
      this.addedSites = [...savedSites]
      this.enableApply.emit({ apply: false, type: this.siteKeyword, data: true });
    }
    else {
      this.enableApply.emit({ apply: false, type: this.siteKeyword, data: false });
    }
  }

  onScroll(): void {
    this.scrollService.scrollEvent(this.autoComplete);
  }

  fetchSitesForCustomer() {
    this.siteList = this.siteFilter.response;
    this.siteFilteredList = this.siteCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSite(value || '')),
    );
  }

  removeSite(site: ISite): void {
    const index = this.addedSites.indexOf(site);
    if (index >= 0) {
      this.addedSites.splice(index, 1);
      if (this.addedSites.length === 0) {
        this.enableApply.emit({ apply: false, type: this.siteKeyword, data: false });
      }
      else {
        this.enableApply.emit({ apply: false, type: this.siteKeyword, data: true });
      }

    }
    else {
      this.enableApply.emit({ apply: false, type: this.siteKeyword, data: false });
    }
    this._filterService.addedSites$.next(this.addedSites);
  }

  selectedSite(siteIdEvent: MatAutocompleteSelectedEvent, siteNameEvent: MatAutocompleteSelectedEvent): void {
    const siteId = siteIdEvent.option.value;
    const siteName = siteNameEvent.option.viewValue;
    if (!this.addedSites.some(site => site.siteId.toLowerCase() === siteId.toLowerCase())) {
      this.addedSites.push({ siteId, siteName });
      this._filterService.addedSites$.next(this.addedSites);
      this.enableApply.emit({ apply: false, type: this.siteKeyword, data: true });
    }
    else {
      this.enableApply.emit({ apply: false, type: this.siteKeyword, data: true });
    }
    this.siteCtrl.setValue(null);
  }

  private _filterSite(value: string): ISite[] {
    const filterValue = value.toLowerCase();
    let filteredSites = this.siteList?.sites.filter(s => s.siteName?.toLowerCase().includes(filterValue));
    if (!this.siteCtrl.value) {
      return filteredSites;
    }
    if (filteredSites?.length === 0) {
      this.siteCtrl.setErrors({ 'invalidSite': true });
      this.enableApply.emit({ apply: true, type: this.siteKeyword });
    }
    else {
      this.siteCtrl.setErrors(null);
      this.enableApply.emit({ apply: false, type: this.siteKeyword });
    }
    return filteredSites;
  }

  ngOnDestroy() {
    this._filterService.addedSites$.next(this.addedSites);
  }

  clearInput(event: MouseEvent){  
    event.stopPropagation();  
    this.siteCtrl.setValue('');     
  }
}
