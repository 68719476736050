<div class="bic-potential-summary-tiles">
  <div class="bic-potential-summary-tile">
    <div class="bic-potential-summary-title">
      {{ summaryTitle }}
    </div>
    <div class="bic-potential-summary-tile-value">
      <span class="bic-potential-summary-tile-currency">
        {{ BicPotentialTotalValue|numberSuffix:transformationType.withCurrency:currencyCode }}</span>
      <span class="bic-potential-summary-tile-code">{{ currencyCode }}</span>
    </div>
  </div>
  <div class="bic-potential-summary-tiles-container">
    <div *ngFor="let tile of tiles">
      <app-value-category-tiles [tile]="tile">
      </app-value-category-tiles>
    </div>
  </div>
</div>
