<div *ngFor="let item2 of evocfilterIterations;let j=index">
    <span class="evoc-label">{{evocFilterNames[j]}}</span>
    <mat-form-field appearance="outline" (click)="getCurrentLevelEVOCOptions(j+1,false)" >        
        @if(evocInputControls[j].getError('invalid'))
        {
            <mat-icon matSuffix><img [src]="errorIcon" alt="Error Icon" /></mat-icon>
        }
        @else
        {
            <mat-icon matSuffix><img class="down-arrow-icon" [src]="downArrowIcon" alt="Arrow Icon" /></mat-icon>
        } 
        <input type="text" aria-label="Number" matInput [placeholder]="evocFilterNames[j]"
            [formControl]="evocInputControls[j]" [matAutocomplete]="auto"
            (click)="getCurrentLevelEVOCOptions(j+1,false)" (keyup)="validateEvocFilter(j+1)"
            id="inputTextColor" [errorStateMatcher]="matcher" #autoCompleteInput>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnEVOC"
            (optionSelected)="getCurrentLevelEVOCOptions(j+1,true)" >
            @for (option of filteredOptionsEvoc | async; track option)
            {
            <mat-option [value]="option" class="list-options">{{option}}</mat-option>
            }
        </mat-autocomplete>
        @if(evocInputControls[j].getError('invalid'))
        {
        <mat-error>{{'No Results found for '+ evocFilterNames[j] }}</mat-error>
        }
    </mat-form-field>
</div>