import { Component, Input } from '@angular/core';
import { BIC_TILE_IMAGE_MAPPING, NOT_FOUND, TRANSFORMATION_TYPE } from '../../constants/app.constants';
import { UserPreferenceService } from '../../../features/tvd-setup/services/user-preference.service';
import { IValueCategoryTiles } from '../../../features/tvd-setup/interfaces/IValueCategoryTiles.interface';

@Component({
  selector: 'app-value-category-tiles',
  templateUrl: './value-category-tiles.component.html',
  styleUrl: './value-category-tiles.component.scss'
})
export class ValueCategoryTilesComponent {
  transformationType = TRANSFORMATION_TYPE;
  @Input() tile: IValueCategoryTiles = { valueCategorytitle: '', value: '' };
  currencyCode: string = '';
  tileImage: string = '';

  constructor(
    private userPreferenceService: UserPreferenceService) { }

  ngOnInit() {
    this.userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences && preferences.currency) {
        this.currencyCode = preferences.currency;
      }
    });
    if (this.tile) {
      this.getBICPotentialTileImage(this.tile.valueCategorytitle);
    }
  }

  getBICPotentialTileImage(tilesData: string): void {
    const tile = BIC_TILE_IMAGE_MAPPING.find(item => item.tileName.toLowerCase() === tilesData.toLowerCase())
    this.tileImage = tile ? tile.imagePath : NOT_FOUND;
  }
}
