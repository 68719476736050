import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
//import { TokenInterceptor } from './core/interceptors/http.interceptor';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { environment } from '../environments/environment';
import {
  MsalInterceptor,
  MsalModule,
  MsalService,

} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { protectedDotnetResources, msalConfig, protectedMulesoftResources } from './auth-config';
import { ELASTIC_APM_SERVICENAME } from './features/tvd-setup/constant/tvd-constant';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ngxUiLoaderConfig } from './shared/constants/app.constants';
import { SharedModule } from './shared/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import defaultLanguage from "../assets/i18n/en.json";
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { MatToolbarModule } from "@angular/material/toolbar";
import { UiLibrariesModule } from './shared/ui-libraries-module/ui-libraries.module';
import { GlobalErrorHandler } from './core/errorhandlers/apm-error-handler';

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setTranslation('en', defaultLanguage);
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApmModule,
    MatToolbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SharedModule,
    UiLibrariesModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          //scopes:loginRequest.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [
            `${environment.API_URL_MULESOFT}`,
            protectedMulesoftResources.scopes],
          [
            `${environment.BIC_MULESOFT}`,
            protectedMulesoftResources.scopes],
          [
            `${environment.MULESOFT_MOCK}`,
            protectedMulesoftResources.scopes],
          [
            `${environment.API_DOTNET_TVD_URL}`,
            protectedDotnetResources.scopes,
          ],
          [
            `${environment.API_DOTNET_TVD_Language_URL}`,
            protectedDotnetResources.scopes,
          ],
          [
            `${environment.API_DOTNET_TVD_Logout_URL}`,
            protectedDotnetResources.scopes,
          ],
          [
            `${environment.CDM_AUTHORIZATION_URL}`,
            protectedMulesoftResources.scopes,
          ]
        ]),
      }
    )
  ],
  providers: [ApmService, provideAnimationsAsync(),
    // CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule]
})
export class AppModule {
  msalUserInfo: UserObject;
  constructor(
    service: ApmService,
    private msal: MsalService
  ) {
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: ELASTIC_APM_SERVICENAME,
      serverUrl: environment.ELASTIC_APM_SERVER_URL,
      environment: environment.ENV_NAME
    });
    this.msalUserInfo = {
      id: this.msal.instance.getAllAccounts()[0]?.localAccountId,
      email: this.msal.instance.getAllAccounts()[0]?.localAccountId,
      username: this.msal.instance.getAllAccounts()[0]?.name
    }
    apm.setUserContext(this.msalUserInfo);
  }
}
