import { Component, Input, SimpleChanges } from '@angular/core';
import { IValueCategoryTiles } from '../../interfaces/IValueCategoryTiles.interface';
import { UserPreferenceService } from '../../services/user-preference.service';
import { BIC_TABLE, TRANSFORMATION_TYPE } from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-best-in-class-summary',
  templateUrl: './best-in-class-summary.component.html',
  styleUrl: './best-in-class-summary.component.scss'
})
export class BestInClassSummaryComponent {
  @Input() tiles: IValueCategoryTiles[] = [];
  summaryTitle: string = BIC_TABLE.TITLE;
  BicPotentialTotalValue: number;
  currencyCode: string;
  transformationType = TRANSFORMATION_TYPE;

  constructor(
    private userPreferenceService: UserPreferenceService
  ) { }

  ngOnInit() {
    this.userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences) {
        this.currencyCode = preferences.currency;
      }
    });
    this.getBICPotentialTotalValue();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['tiles'] && changes['tiles'].previousValue !== changes['tiles'].currentValue) {
      this.getBICPotentialTotalValue();
    }
  }

  getBICPotentialTotalValue() {
    this.BicPotentialTotalValue = this.tiles.reduce((sum, tile) => sum + Number(tile.value), 0);
  }
}
