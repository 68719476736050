<div class="mat-elevation-z8 bic-container">
  <div class="bic-title">
    <h3>{{'TVD.BIC' | translate }}</h3>
  </div>
  <br /><br />
  <mat-divider></mat-divider>
  <div class="bic-body">
    <app-best-in-class-summary [tiles]="tilesData"></app-best-in-class-summary>
    <mat-divider vertical class="bic-vertical-divider"></mat-divider>
    <div class="bic-form-container">
      <mat-form-field appearance="outline" class="bic-filter-wrapper">
        <mat-label>{{bicAsset.LABEL}}</mat-label>
        <mat-select [formControl]="valCategoryFormControl" (selectionChange)="applyFilter()">
          @for (item of valueCategoryInfo.valueCategoryLst; track item)
          {
          <mat-option [value]="item">{{item}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <app-table [tableData]="bicTableData" 
      [tableConfiguration]="BIC_Configuration"
      (rowInfo)="displayViewMore($event)">
    </app-table>
    <mat-spinner *ngIf="loader" style="margin:auto;" [diameter]="90" [strokeWidth]="8"></mat-spinner>
    </div>
  </div>
</div>
<br /><br /><br />
