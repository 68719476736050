import { Component } from '@angular/core';
import { BIC_LOCATION_MAPPER, BIC_MODAL, BIC_TABLE, generic_tableConfiguration } from '../../../../shared/constants/app.constants';
import { ITableConfiguration } from '../../interfaces/ITable';
import { DialogRef } from '@angular/cdk/dialog';
import { IBICSiteDetail, IBICSiteResponse, IOfferingCalculatedValues } from '../../interfaces/IBIC';
import { FilterService } from '../../services/filter.service';
import { UserPreferenceService } from '../../services/user-preference.service';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-modal-view',
  templateUrl: './modal-view.component.html',
  styleUrl: './modal-view.component.scss'
})
export class ModalViewComponent {

  table_Configuration: ITableConfiguration = null;
  locationInfo:IBICSiteDetail;
  rowInfo:IOfferingCalculatedValues;
  BIC_LITERALS=BIC_MODAL;
  uom:string;
  currency:string;
  constructor(private _customerService:CustomerService, private _userPreferenceService:UserPreferenceService, public dialogRef: DialogRef<ModalViewComponent>, private _filterService:FilterService){}

  ngOnInit()
  {
    this.rowInfo=this.dialogRef?.config?.data;
    this.generate_BIC_Configuration();
    this.fetchLocationInfo();
  }

  fetchLocationInfo()//mock API call
  {
      let customerID = this._filterService.customerInfo.value.customerID;
      if (customerID) {
        this._userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
          if (preferences) {
            this.uom = preferences.uom;
            this.currency = preferences.currency;
          }
        
        const reqPayload = this._filterService.getAppliedOptionalFilters(this.uom, this.currency,true);
        const encodedOfferingName=btoa(this.rowInfo.offeringName);//converting string to base64
        this._customerService.fetchBICLocation<IBICSiteResponse>(customerID, reqPayload, encodedOfferingName).subscribe(
          res => {
            this.locationInfo = res;
          });
      });
  }
}

  generate_BIC_Configuration() {
    let columnList = BIC_LOCATION_MAPPER.map(item => item.fieldName);
    this.table_Configuration = {
      columnList: columnList,
      mapper: BIC_LOCATION_MAPPER,
      tableTitle: '',
      tableType: '',
      pageSizeOptions: generic_tableConfiguration.pageSizeOptions,
      pageSize: BIC_TABLE.DEFAULT_PAGESIZE,
      img: BIC_TABLE.ICON
    }
  }

  closeModel() {
    this.dialogRef.close();
  }

}
