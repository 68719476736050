<div *ngFor="let item of cvocfilterIterations;let i=index">
    <mat-form-field appearance="outline" (click)="getCurrentLevelCVOCOptions(i+1)">
        @if(cvocInputControls[i].getError('invalid'))
        {
            <mat-icon matSuffix><img [src]="errorIcon" alt="Error Icon" /></mat-icon>
        }
        @else
        {
            <mat-icon matSuffix><img class="down-arrow-icon" [src]="downArrowIcon" alt="Arrow Icon" /></mat-icon>
        }        
        <input type="text" aria-label="Number" matInput [placeholder]="'Level '+(i+1)"
            [formControl]="cvocInputControls[i]" [matAutocomplete]="auto" (click)="getCurrentLevelCVOCOptions(i+1)"
            (keyup)="validateCvocFilter(i+1)" [errorStateMatcher]="matcher" id="inputTextColor" #autoCompleteInput>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFnCVOC"
            (optionSelected)="getCurrentLevelCVOCOptions(i+1,true)">
            @for (option of filteredOptions_cvoc | async; track option)
            {
            <mat-option [value]="option">{{option.hierarchyName}}</mat-option>
            }
        </mat-autocomplete>
        @if(cvocInputControls[i].getError('invalid'))
        {
        <mat-error>{{'No Results found for Level '+(i+1) }}</mat-error>
        }
    </mat-form-field>
</div>