<div class="bic-potential-tile">
  <div class="bic-potential-tile-image">
    <img [src]="tileImage" alt="vector" />
  </div>
  <div class="bic-potential-tile-details">
    <div class="bic-potential-tile-details-text">
      {{ tile.valueCategorytitle }}
    </div>
    <div class="bic-potential-tile-details-value">
      {{ tile.value | numberSuffix:transformationType.withCurrency:currencyCode }}
    </div>
  </div>
</div>
