<mat-divider></mat-divider>

<div class="operating-filter-container">
  <span class="title-label">{{opTypeLiterals.TITLE}}</span><br /><br />
  <mat-form-field appearance="outline" class="opFormStyle">
    <mat-label>{{opTypeLiterals.LABEL}}</mat-label>
    <mat-icon matPrefix class="search-icon">search</mat-icon>
    @if(opTypeInput.value && opTypeInput.getError('invalid'))
    {
    <mat-icon matSuffix><img [src]="errorIcon"
        alt="Error Icon" /></mat-icon>
    }
    @else
    {
    <mat-icon class="close-icon" *ngIf="opTypeInput.value" matSuffix
      (click)="clearInput($event)">highlight_off</mat-icon>
    }
    <input type="text" matInput [formControl]="opTypeInput" #autoCompleteInput
      [matAutocomplete]="auto" (keyup)="validateOPType()"
      [errorStateMatcher]="matcher">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
      (optionSelected)="validateOPType(true)">
      @for(op of filteredOptions | async; track op){
      <mat-optgroup class="parent-operating-type">{{op.operatingType}}
        @for(subOp of getSubTypesObservable(op.subTypes)| async; track subOp)
        {
        <mat-option class="sub-option-style"
          [value]="subOp">{{subOp}}</mat-option>
        }
      </mat-optgroup>
      <mat-divider></mat-divider>
      }
    </mat-autocomplete>
    @if(opTypeInput.getError('invalid'))
    {
    <mat-error>{{opTypeLiterals.NO_RESULT}}</mat-error>
    }
  </mat-form-field>
</div>