import { Injectable, QueryList } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private sectionToScrollSubject = new BehaviorSubject<string | null>(null);
  sectionToScroll$ = this.sectionToScrollSubject.asObservable();

  scrollToSection(sectionId: string): void {
    if (sectionId) {
      this.sectionToScrollSubject.next(sectionId);
    }
  }

  scrollEvent(autoComplete: MatAutocompleteTrigger, autoCompleteChained?: QueryList<MatAutocompleteTrigger>): void {
    if (autoComplete?.panelOpen) {
      autoComplete.updatePosition();
    }
    if (autoCompleteChained) {
      autoCompleteChained.forEach(trigger => {
        if (trigger.panelOpen) {
          trigger.updatePosition();
        }
      });
    }
  }
}
