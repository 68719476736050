<form>
  <mat-form-field class="siteWrapper" appearance="outline">
      <mat-label>{{searchLabel}}</mat-label>
      <mat-icon matPrefix class="search-icon">search</mat-icon>
      @if(siteCtrl.getError('invalidSite'))
        {
            <mat-icon matSuffix><img [src]="errorIcon" alt="Error Icon" /></mat-icon>
        }
        @else
        {
          <mat-icon class="close-icon" *ngIf="siteCtrl.value" matSuffix (click)="clearInput($event)">highlight_off</mat-icon>
        } 
      <!-- <mat-icon class="close-icon" *ngIf="siteCtrl.value" matSuffix (click)="clearInput($event)">highlight_off</mat-icon> -->
      <input class="site-text" matInput [formControl]="siteCtrl" [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [errorStateMatcher]="matcher" #autoCompleteInput/>
     <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSite($event,$event)">
      @for (site of siteFilteredList | async; track site) {
      <mat-option [value]="site.siteId">{{site.siteName}}</mat-option>
      }
    </mat-autocomplete>
    
    @if(siteCtrl.getError('invalidSite'))
    {
    <mat-error>{{SiteErrorText}}</mat-error>
    }
  </mat-form-field>
  <mat-chip-grid #chipGrid aria-label="sites">
    @for (site of addedSites; track site) {
    <mat-chip-row id="chipStyle" (removed)="removeSite(site)">
      {{site.siteName}}
      <button matChipRemove [attr.aria-label]="'remove ' + site">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
</form>