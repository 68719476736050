<div class="view-more-container">
    <div class="top-section">
        <div class="header">{{BIC_LITERALS.BIC_HEADING_1}}</div>
        <button class="closeButton" (click)="closeModel()">
            <img [src]="BIC_LITERALS.CLOSE_ICON" />
        </button>
    </div>
    <div class="bic-title">
        <h3>{{rowInfo.offeringName}}</h3>
    </div>
    <br />
    <mat-divider></mat-divider>
    <br />
    <mat-dialog-content>
        <div class="header-2">{{BIC_LITERALS.BIC_HEADING_2}}</div>
        <app-table [tableData]="locationInfo.offeringSites" [tableConfiguration]="table_Configuration"></app-table>
    </mat-dialog-content>
</div>