import { Component, ElementRef, ViewChild } from '@angular/core';
import { CustomerService } from '../../services/customer.service';
import { CustomerApi, ICustomerFilters, ICustomerInfo } from '../../interfaces/customer.interface';
import * as Highcharts from 'highcharts';
import { IL1Detail, IL1DetailsResponse, IL2Detail, IL2DetailsResponse } from '../../interfaces/IDetailsL1';
import { default as _rollupMoment, Moment } from 'moment';
import _moment from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../services/translation.service';
import { FilterService } from '../../services/filter.service';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';
import { TVD_DATE_FORMAT } from '../../constant/tvd-constant';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';
import { BIC_TABLE, CUSTOMER_NOT_FOUND, LANDINGPAGE, PROGRAM_TABLE, UI_CONTROL_DEFAULT_VALUE_ALL, UOM, USD } from '../../../../shared/constants/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { highChartConfig } from '../../interfaces/IhighChart.interface';
import { IProgramDetails } from '../../interfaces/IProgram.interface';
import { UserPreferenceService } from '../../services/user-preference.service';
import { ITableConfiguration } from '../../interfaces/ITable';
import { ScrollService } from '../../services/scroll.service';
import { IBICInfo, IBICResponse, IOfferingCalculatedValues } from '../../interfaces/IBIC';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const moment = _rollupMoment || _moment;
const default_Start_Date = moment().subtract(1, 'years').startOf('month');
const default_End_Date = moment().startOf('month').subtract(1, 'days');
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
  providers: [provideMomentDateAdapter(TVD_DATE_FORMAT)],
})
export class LandingPageComponent {
  @ViewChild('txtCustomerInput') txtCustomerInput: ElementRef;
  @ViewChild(PROGRAM_TABLE.TEMPLATE_REFERENCE, { read: ElementRef }) programSection: ElementRef;
  @ViewChild(BIC_TABLE.TEMPLATE_REFERENCE, { read: ElementRef }) bicSection: ElementRef;
  siteFlag:boolean=false;
  rowInfoForSiteBIC:IOfferingCalculatedValues=null;
  bicSummary: number;
  isBicEnabled: boolean = false;
  bicInfo: IBICInfo;
  customerNotFoundMessage: string = CUSTOMER_NOT_FOUND
  customerData: ICustomerInfo[];
  errorOccured: boolean;
  selectedCustomer: ICustomerInfo;
  applyClickChanges: ICustomerInfo;
  custDisplayName: string;
  L1TilesData: IL1Detail;
  searchText: string = '';
  filteredCustomers: ICustomerInfo[];
  highlightedCustomer: ICustomerInfo;
  tvdConvertedToMillion: number;
  totalOpportunityInMillion: number;
  heading: string = 'My Total Value Delivered';
  L2TilesData: IL2Detail;
  displayTiles: boolean;
  matcher = new CustomerNameErrorStateMatcher();
  showMainFilterToggle: boolean = false;
  customerDetails: ICustomerInfo;
  custNameControl = new FormControl('', Validators.required);
  filteredOptions: Observable<ICustomerInfo[]>;
  applyDisabled: boolean = false;
  siteListLength: number;
  startDate = new FormControl(default_Start_Date, [
    this.startDateValidator.bind(this),
  ]);
  endDate = new FormControl(default_End_Date, [
    this.endDateValidator.bind(this),
  ]);
  start_date_max = new Date(); //current date
  start_date_min = new Date(this.start_date_max.getFullYear() - 2, 0, 1); //2 years ago
  end_date_min = new Date();
  end_date_max = new Date();
  disableEndDate: boolean = true;
  totalValue: number = 0;
  L2TilesDataSorted = [];
  errMessage: string = '';
  title: string = '';
  homeButtonURL: string = environment.DI_APP_LINK;
  customerDataId: any;
  selectedCustomerID: any;
  totalValueDelivered: string = '';
  totalValuePipeline: string = '';
  bestInClassPotential: string = '';
  isLoading: boolean = false;
  filteredL2Data = [];
  programTableData: IProgramDetails[];
  currencyCode: any = USD;
  unitOfMeasure: string = UOM;
  currencySymbol: string;
  programTableConfiguration: ITableConfiguration;
  programFilters: CustomerApi;
  private scrollSubscription!: Subscription;
  customerFiltersReqObjParams: ICustomerFilters;
  constructor(
    //public ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _filterService: FilterService,
    private customerService: CustomerService,
    public translateService: TranslationService,
    public translate: TranslateService,
    private userPreferenceService: UserPreferenceService,
    private scrollService: ScrollService
    // public cookieService: CookieService,
  ) {
    this.start_date_max = moment()
      .subtract(1, 'months')
      .startOf('month')
      .toDate();
    this.end_date_max = moment().startOf('month').subtract(1, 'days').toDate();
    this.start_date_max = moment()
      .subtract(1, 'months')
      .startOf('month')
      .toDate();
    this.end_date_max = moment().startOf('month').subtract(1, 'days').toDate();
    translate.setDefaultLang('en');
    this.translate.stream('TVD').subscribe((values) => {
      this.title = values.MYTOTALVALUEDELIVERED;
      this.totalValueDelivered = values.TOTALVALUEDELIVERED;
      this.totalValuePipeline = values.TOTALVALUEPIPELINE;
      this.bestInClassPotential = values.BIC;
    });
  }

  checkForSite(siteInfo:any)
  {
    this.siteFlag=siteInfo.flag;
    this.rowInfoForSiteBIC=siteInfo?.bicRowInfo||null;
  }

  ngOnInit() {
    this.getUserPreferences();
    if (this.currencyCode) {
      this.currencySymbol = this.userPreferenceService.getCurrencySymbol(this.currencyCode);
    } else {
      this.currencySymbol = ''; // Set a default currency symbol or handle accordingly
    }
    this.isLoading = true;
    this.scrollHide();
    const startDate = this.startDate.value.format(LANDINGPAGE.DATEFORMAT);
    const endDate = this.endDate.value.format(LANDINGPAGE.DATEFORMAT);
    this._filterService.startDate.next(startDate);
    this._filterService.endDate.next(endDate);
    this.customerService
      ?.getCustomerList()
      ?.subscribe((customerData: CustomerApi) => {
        if (customerData) {
          this.customerData = customerData?.response;
          this.selectedCustomer = this.customerData[0];
          this.custDisplayName = this.selectedCustomer?.customerName;
          this.custDisplayName = this.selectedCustomer?.customerName;
          this.filteredOptions = this.custNameControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
          if (this.selectedCustomer && this.selectedCustomer.customerID) {
            this._filterService.customerInfo.next(this.selectedCustomer);
            const customerID = this.selectedCustomer?.customerID;
            this.l1_service(customerID, startDate, endDate);
            this.l2_service(customerID, startDate, endDate);
          }
        }
      });
    this.translateService.selectedLanguage();
    this.customerService.errorOccured?.subscribe((x) => {
      if (x == true) {
        this.errorOccured = x;
        this.openDialog();
        this.isLoading = false;
        this.scrollShow();
      }
    });
    this.scrollSubscription = this.scrollService.sectionToScroll$.subscribe(sectionId => {
      if (sectionId) {
        this.scrollToSection(sectionId);
      }
    });

    this._filterService.bicValueCategory$.subscribe(
      res => {
        if (res) {
          this.fetchBICInfo();
        }
      }
    );
  }

  public l1_service(
    customerID: string,
    stDate: string,
    endDate: string,
    filterReq: any = null,
  ) {
    this.customerService
      ?.getL1TileDetails(customerID, stDate, endDate, filterReq, this.unitOfMeasure, this.currencyCode)
      .subscribe((l1_data: IL1DetailsResponse) => {
        if (l1_data?.response) {
          this.L1TilesData = l1_data?.response;
          this.L1TilesData?.L1Tiles?.forEach(tile => {
            // Filter the segregatedInformation array to keep only objects with a non-empty quantityDeliveredUnit
            tile.segregatedInformation = tile.segregatedInformation?.
              filter(info => info.quantityDeliveredUnit && Number(info.quantityDelivered) > 0.5)
          });
          this.getTVDHighChartData(this.L1TilesData); // TVD chart
          this.checkEmptyResponse();

          this.fetchBICInfo();
        }
      });
  }

  filterViewMore() {
    // Iterate through each tile in the L2Tiles array
    this.L2TilesData?.L2Tiles?.forEach(tile => {
      // Filter the segregatedInformation array to keep only objects with a non-empty quantityDeliveredUnit
      tile.segregatedInformation = tile.segregatedInformation?.
        filter(info => info.quantityDeliveredUnit && Number(info.quantityDelivered) > 0.5);
    });
  }

  //  Checks if the L1TilesData or L2TilesData objects are empty. If either opens a snack bar to notify the user
  checkEmptyResponse() {
    if (!Object.keys(this.L1TilesData).length || (this.L2TilesData && !Object.keys(this.L2TilesData).length)) {
      this.openSnackBar();
    }
  }

  l2_service(customerID: string, stDate?: string, endDate?: string, filterReq: any = null) {
    this.customerService
      ?.getL2TileDetails(customerID, stDate, endDate, filterReq, this.unitOfMeasure, this.currencyCode)
      .subscribe((l2_data: IL2DetailsResponse) => {
        if (l2_data.response) {
          this.L2TilesData = l2_data?.response;
          this.filterViewMore();
          this.L2TilesDataSorted = this.sortMultipleFieldsByDescending(this.L2TilesData.L2Tiles, 'totalValueDelivered', 'opportunityAmount');
          this.L2TilesDataSorted = this.sortMultipleFieldsByDescending(this.L2TilesData.L2Tiles, 'totalValueDelivered', 'opportunityAmount');
          if (this.L2TilesData) {
            this.filteredL2Data = this.L2TilesDataSorted?.filter((tiles) =>
              tiles.totalValueDelivered == 0 &&
                tiles.opportunityAmount == 0 &&
                tiles.segregatedInformation.length == 0
                ? ''
                : tiles
            );
          } else {
            this.filteredL2Data = [];
          }
        }
      });
  }


  /**
   * Fetches BIC (Best-In Class) information for the selected customer.
   *
   * This method constructs a request payload using the applied optional filters
   * and then calls the `getBICDetails` method of the `customerService` to fetch
   * the BIC details for the selected customer. Upon receiving the response, it
   * updates the `bicInfo` and `isBicEnabled` properties, and notifies other
   * components by pushing the BIC information to the `bicInfo$` observable.
   * Finally, it calculates the total value using the `calculateTotalValue` method.
   *
   * @returns {void}
   */
  fetchBICInfo(): void {

    const reqPayload = this._filterService.getAppliedOptionalFilters(this.unitOfMeasure, this.currencyCode, true)
    this.customerService.getBICDetails<IBICResponse>(this.selectedCustomer.customerID, reqPayload).subscribe(
      res => {
        this.bicInfo = res;
        this.isBicEnabled = this.bicInfo.isBicFlag;
        this.customerService.bicInfo$.next(this.bicInfo);

        this.calculateTotalValue(this.L1TilesData);
      });
  }

  displayFn(customerName: string) {
    return customerName;
  }

  openDialog() {
    this.dialog.open(ErrorDialogComponent);
  }

  //below filters the list of options based on keystroke
  public _filter(value: string): Array<ICustomerInfo> {
    const filterValue = value.toLowerCase();
    return this.customerData.filter((option) =>
      option.customerName?.toLowerCase().includes(filterValue)
    );
  }

  //Validator for Start Date
  startDateValidator(control: FormControl) {
    const selectedStartDate = control.value;
    this.end_date_min = selectedStartDate; //set the startdate as min of endDate.
    this.disableEndDate = false; //enable the enddate picker!
  }

  //Validator for End Date
  endDateValidator(control: FormControl) {
    const selectedEndDate = control.value;
    this.start_date_max = selectedEndDate; //set the enddate as max of startDate.
  }
  openSnackBar() {

    this._snackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'bottom'
    });
  }
  //Below method is called when user completes the selection from DatePicker!
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    dateType: String
  ) {
    const ctrlValue = moment();
    ctrlValue.year(normalizedMonthAndYear.year());
    if (dateType === 'start') {
      ctrlValue.month(normalizedMonthAndYear.month()).startOf('month');
      this.startDate.setValue(ctrlValue);
    } else {
      ctrlValue.month(normalizedMonthAndYear.month()).endOf('month');
      this.endDate.setValue(ctrlValue);
    }

    datepicker.close();
  }

  openFilterView() {
    this.showMainFilterToggle = !this.showMainFilterToggle;
    this.custNameControl.setValue(this.selectedCustomer.customerName);
    this._filterService.filterContainerName.next('customerFilter');
    this._filterService.filterContainerName.subscribe((name) => {
      if (name.toLowerCase() === 'allfilters' && this.showMainFilterToggle) {
        this.showMainFilterToggle = false;
      }
    });
  }

  highlightOption(customer: ICustomerInfo): void {
    this.highlightedCustomer = customer;
  }
  removeHighlightOption(customer: ICustomerInfo): void {
    this.highlightedCustomer = null;
  }

  isHighlighted(customer: ICustomerInfo): boolean {
    return this.highlightedCustomer === customer;
  }

  calculateTotalValue(data: IL1Detail) {

    this.totalValue = 0;

    let total = 0;
    data?.L1Tiles.map(
      (data) =>
      (total +=
        this.convertTONumber(data.totalValueDelivered) +
        this.convertTONumber(data.opportunityAmount))
    );

    this.totalValue = total + this.bicInfo.bicPotentialSum;
  }

  getTVDHighChartData(customerData: IL1Detail): void {
    const highChartTvdConfig: highChartConfig = {
      id: LANDINGPAGE.TVD,
      border: LANDINGPAGE.TVDBORDER,
      catogory: this.totalValueDelivered
    }
    const highChartEnterpriseConfig: highChartConfig = {
      id: LANDINGPAGE.ENTERPRISESITEPIPE,
      border: LANDINGPAGE.ENTERPRISESITEPIPEBORDER,
      catogory: this.totalValuePipeline
    }
    let tvdSUM = 0;
    let totalOpportunitySUM = 0;
    if (customerData?.L1Tiles) {
      customerData.L1Tiles.forEach((tile) => {
        tvdSUM += this.convertTONumber(tile.totalValueDelivered);
        totalOpportunitySUM += this.convertTONumber(tile.opportunityAmount);
      });
    }
    this.tvdConvertedToMillion = tvdSUM;
    this.totalOpportunityInMillion = totalOpportunitySUM;

    this.highCharts(tvdSUM, highChartTvdConfig);
    this.highCharts(totalOpportunitySUM, highChartEnterpriseConfig);
    this.isLoading = false;
    this.scrollShow();
  }

  scrollShow() {
    if (!this.isLoading) document.body.classList.remove('no-scroll');
  }
  scrollHide() {
    if (this.isLoading) document.body.classList.add('no-scroll');
  }
  convertTONumber(str: string): number {
    return Number(str?.replace(/\,/g, ''));
  }

  highCharts(value: number, highChartConfig: highChartConfig): void {
    // @ts-ignore
    Highcharts.chart(highChartConfig.id, {
      chart: {
        type: 'bar',
        margin: [0, 0, 0, 0],
        height: 40,
        width: 300,
        backgroundColor: 'transparent',
      },
      plotOptions: {
        bar: {
          borderRadius: '50%',
        },
        series: {
          groupPadding: 0.1,
          pointPadding: 0.4,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
        min: 0,
        max: this.tvdConvertedToMillion + this.totalOpportunityInMillion
      },
      tooltip: {
        enabled: true,
        headerFormat: '<table>',
        pointFormat:
          `<tr><td style="padding:0;font-size:1.5em;font-family: Roboto">
          {series.userOptions.category}: </td>
          <td style="padding:0; font-size: 1.5em;font-family: Roboto"><b>${this.currencySymbol}&nbsp;{point.y:,.2f}</b></td></tr>`,
      },
      credits: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      series: [
        {
          borderColor: highChartConfig.border,
          showInLegend: false,
          category: highChartConfig.catogory,
          data: [
            { y: value, color: highChartConfig.border },
          ],
        },
      ],
    });
  }

  customerSelection() {
    //check below if the customer entered custName is present in customerList!
    if (this.custNameControl.value) {
      this.selectedCustomer = this.customerData.find(
        (customer) => customer.customerName === this.custNameControl.value
      );
      if (this.selectedCustomer) {
        this.applyDisabled = false;
      } else {
        this.applyDisabled = true;
      }
    } else {
      this.applyDisabled = true;
    }
  }
  
  applyFilters() {
    if (this.selectedCustomer && this.startDate.value && this.endDate.value) {
      this._filterService.customerInfo.next(this.selectedCustomer);
      this._filterService.resetAllSavedFilterData();
      this._filterService.bicValueCategory$.next(UI_CONTROL_DEFAULT_VALUE_ALL);
      this._filterService.bicValueCategoryList$.next([]); // clearing the BehaviorSubject once customer is reset
      this._filterService.appliedCVOC.next(null);
      this._filterService.appliedEVOC.next(null);
      this._filterService.appliedCustomerDivision.next(null);
      this._filterService.appliedSites$.next(null);
      this._filterService.appliedOperatingType$.next(null);
      this.isLoading = true;
      this.scrollHide();
      this.showMainFilterToggle = false;
      this.applyClickChanges = this.selectedCustomer;
      let stDate = this.startDate.value.format(LANDINGPAGE.DATEFORMAT);
      let endDate = this.endDate.value.format(LANDINGPAGE.DATEFORMAT);
      this._filterService.startDate.next(stDate); //saving dates for other components
      this._filterService.endDate.next(endDate);
      this.l1_service(this.selectedCustomer.customerID, stDate, endDate);
      this.l2_service(this.selectedCustomer.customerID, stDate, endDate);
      this.custDisplayName = this.selectedCustomer.customerName;      
      this.getSiteLength(this.selectedCustomer.customerID);
      this._snackBar.dismiss();
    }
  }

  clearFilter() {
    this.custNameControl.setValue(null);
    this.custNameControl.setErrors(null);
    this.applyDisabled = true;
    this.startDate = new FormControl(default_Start_Date, [
      this.startDateValidator.bind(this),
    ]);
    this.endDate = new FormControl(default_End_Date, [
      this.endDateValidator.bind(this),
    ]);
    this.startDate = new FormControl(default_Start_Date, [
      this.startDateValidator.bind(this),
    ]);
    this.endDate = new FormControl(default_End_Date, [
      this.endDateValidator.bind(this),
    ]);
  }

  fetchOptionalFiltersResults(allFilterObj: any) {
    this.isLoading = true;
    this.scrollHide();
    this.l1_service(
      this.selectedCustomer.customerID,
      this.startDate.value.format(LANDINGPAGE.DATEFORMAT),
      this.endDate.value.format(LANDINGPAGE.DATEFORMAT),
      allFilterObj
    );
    this.l2_service(
      this.selectedCustomer.customerID,
      this.startDate.value.format(LANDINGPAGE.DATEFORMAT),
      this.endDate.value.format(LANDINGPAGE.DATEFORMAT),
      allFilterObj
    );
    this._snackBar.dismiss();
  }

  /**
 * Retrieves the list of sites for a given customer and sets the site list length.
 *
 * @param {string} customerID - The unique identifier for the customer.
 *
 * Ensure that _filterService is properly injected and the getSites method is implemented.
 */
  getSiteLength(customerID: string) {
    this._filterService.getSites(customerID).subscribe((res) => {
      this.siteListLength = res.response.sites.length;
    })
  }

  /**
 * Sorts an array of objects in descending order based on primary and secondary fields.
 * @param data - The array of objects to sort.
 * @param primaryField - The key of the primary field to sort by.
 * @param secondaryField - The optional key of the secondary field to sort by.
 * @returns The sorted array of objects.
 */
  sortMultipleFieldsByDescending<T>(
    data: T[],
    primaryField: keyof T,
    secondaryField?: keyof T
  ): T[] {
    return data?.sort((element1, element2) => {

      const primaryValues = [this.parseValue(element1[primaryField]), this.parseValue(element2[primaryField])];

      // Sort by the primaryField
      if (primaryValues[0] !== primaryValues[1]) {
        return primaryValues[0] > primaryValues[1] ? -1 : 1;
      }

      // If secondary field is provided, extract and parse secondary field values
      if (secondaryField) {
        const secondaryValues = [this.parseValue(element1[secondaryField]), this.parseValue(element2[secondaryField])];

        // Sort by the secondary field if primaryField values are equal
        if (secondaryValues[0] !== secondaryValues[1]) {
          return secondaryValues[0] > secondaryValues[1] ? -1 : 1;
        }
      }

      return 0; // Return 0 if both primaryField and secondary values are equal
    });
  }

  /**
   * Parses a value into a number, handling various input types.
   * @param value - The value to parse.
   * @returns The parsed number, or -Infinity if the value is null, undefined, or non-numeric.
   */
  parseValue(value: any): number {
    if (value === null || value === undefined) return -Infinity; // Treat null/undefined as smallest value
    if (typeof value === 'number') return value; // No need to parse if it's already a number
    const numericValue = parseFloat(value.toString().replace(/,/g, '')); // Handle numeric strings
    return isNaN(numericValue) ? -Infinity : numericValue; // Handle non-numeric values
  }

  getUserPreferences(): any {
    this.userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences) {
        this.currencyCode = preferences.currency;
        this.unitOfMeasure = preferences.uom;
      }
    })
  }

  scrollToSection(section: string): void {
    
    switch (section) {
      case PROGRAM_TABLE.TEMPLATE_REFERENCE:
        this.scrollToElement(this.programSection, -60);
        break;
      case BIC_TABLE.TEMPLATE_REFERENCE:
        this.scrollToElement(this.bicSection);
        break;
      default:
        console.error('Unknown section:', section);
    }
  }
  
  scrollToElement(element: ElementRef, offset:number = 0) {
    if (element?.nativeElement) {
      // Calculate the top position considering the offset
      const elementPosition = element.nativeElement.offsetTop + offset;

      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    } else {
      console.error('Element not found');
    }
  }
}